import React from 'react';

const Home = () => (
    <div className="text-center p-10">
        <h1 className="text-5xl font-bold">Welcome to My Photography Portfolio</h1>
        <p className="mt-4">Showcasing the best of my work</p>
        {/* Add hero image or carousel here */}
    </div>
);

export default Home;
