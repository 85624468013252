import React from 'react';

const Footer = () => (
    <footer className="bg-gray-800 text-white p-4 text-center">
        <div className="container mx-auto">
            <p>&copy; 2024 NetViper LLC. All rights reserved.</p>
        </div>
    </footer>
);

export default Footer;
