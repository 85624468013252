import React, { useEffect, useState } from 'react';

const Portfolio = () => {
    const [albums, setAlbums] = useState([]);
    const [photos, setPhotos] = useState([]);
    const [selectedAlbum, setSelectedAlbum] = useState(null);
    const [previewToken, setPreviewToken] = useState('');
    const [accessToken, setAccessToken] = useState(null);

    useEffect(() => {
        const login = async () => {
            const response = await fetch('http://aphroditeriding.com/api/v1/session', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ username: 'admin', password: 'test123!' })
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            console.log("Login response data:", data);
            setAccessToken(data.access_token);
            setPreviewToken(data.previewToken || 'e2gxibr9');
            return data.access_token;
        };

        const fetchAlbums = async (token) => {
            const response = await fetch('http://aphroditeriding.com/api/v1/albums?count=100', {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            console.log("Fetched albums data:", data);

            const favorites = data.filter(album => album.Favorite);
            console.log("Filtered favorite albums:", favorites);
            setAlbums(favorites);
        };

        login().then(token => {
            fetchAlbums(token);
        }).catch(error => {
            console.error('There was a problem with the fetch operation:', error);
        });
    }, []);

    const handleAlbumClick = async (albumId) => {
        console.log("Album clicked:", albumId);  // Debug log
        if (!albumId) {
            console.error('Album ID is undefined');
            return;
        }
        setSelectedAlbum(albumId);
        const response = await fetch(`http://aphroditeriding.com/api/v1/albums/${albumId}/photos`, {
            headers: { 'Authorization': `Bearer ${accessToken}` }
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        console.log(`Fetched photos for album ${albumId}:`, data);
        setPhotos(data);
    };

    const getThumbnailUrl = (photoUid) => {
        if (!previewToken || !accessToken) {
            console.error('Preview token or access token is not set');
            return '';
        }
        return `http://aphroditeriding.com/api/v1/t/${photoUid}/${previewToken}/tile_500`;
    };

    return (
        <div className="container mx-auto p-10">
            <h1 className="text-4xl font-bold mb-8">Portfolio</h1>
            {selectedAlbum ? (
                <div>
                    <button onClick={() => setSelectedAlbum(null)} className="mb-4">Back to Albums</button>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                        {photos.map(photo => (
                            <div key={photo.ID} className="border p-4">
                                <img src={getThumbnailUrl(photo.UID)} alt={photo.Title} />
                                <h2 className="text-2xl font-bold">{photo.Title}</h2>
                            </div>
                        ))}
                    </div>
                </div>
            ) : (
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                    {albums.map(album => (
                        <div
                            key={album.ID}
                            className="border p-4"
                            onClick={() => handleAlbumClick(album.ID)}
                        >
                            <h2 className="text-2xl font-bold">{album.Title}</h2>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default Portfolio;
